<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="allStudyYears"
      sort-by="calories"
      class="elevation-1"
      :loading="isGettingData"
      loading-text="يتم جلب البيانات ...الرجاء الانتظار"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title class="d-none d-sm-flex"
            >الأعوام الدراسية</v-toolbar-title
          >
          <v-divider class="mx-4 d-none d-sm-flex" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            class="d-none d-sm-flex"
            @click="showAddNewyear = true"
            bottom
            color="blue darken-4"
            dark
            fab
            fixed
            left
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-dialog v-model="dialog" max-width="800px">
            <v-card>
              <v-card-title class="accent white--text darken-2"
                >تعديل البيانات</v-card-title
              >
              <v-container>
                <v-row>
                  <div>
                    <v-card flat width="800px" class="mx-auto">
                      <v-card-text>
                        <v-form ref="form">
                          <v-row class="mb-2">
                            <v-col cols="12">
                              <v-text-field
                                prepend-icon="mdi-account-circle"
                                placeholder="الاسم"
                                v-model="editedItem.num"
                                required
                              />
                            </v-col>
                          </v-row>
                          <v-row class="mb-2">
                            <v-col>
                              <v-autocomplete
                                v-model="classes"
                                :items="classOptions"
                                :loading="loadingClasses"
                                :search-input.sync="search"
                                aria-required
                                chips
                                hide-details
                                hide-selected
                                cache-items
                                item-text="name"
                                item-value="id"
                                label="الشعب والصفوف"
                                prepend-icon="mdi-account-circle"
                                append-icon
                                placeholder="بحث عن صف حسب الاسم"
                                return-object
                                solo
                                multiple
                              >
                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-title>
                                      البحث عن
                                      <strong>الصفوف</strong>
                                    </v-list-item-title>
                                  </v-list-item>
                                </template>
                                <template
                                  v-slot:selection="{
                                    attr,
                                    on,
                                    item,
                                    selected,
                                  }"
                                >
                                  <v-chip
                                    v-bind="attr"
                                    :input-value="selected"
                                    color="blue-grey"
                                    class="white--text"
                                    v-on="on"
                                  >
                                    <v-icon left>mdi-account-circle</v-icon>
                                    <span v-text="item.name"></span>
                                  </v-chip>
                                </template>
                                <template v-slot:item="{ item }">
                                  <v-list-item-avatar
                                    color="indigo"
                                    class="headline font-weight-light white--text"
                                  ></v-list-item-avatar>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-text="item.name"
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-icon>mdi-account-circle</v-icon>
                                  </v-list-item-action>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer />

                <v-btn
                  color="success"
                  class="mr-4"
                  @click="save"
                  :loading="isClick"
                  >حفظ</v-btn
                >
                <v-btn text @click="dialog = false">الغاء</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.action="{ item }">
        <v-btn
          rounded
          color="primary"
          dark
          @click="editStudyYear(item)"
          v-if="item.isOpen == true"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <!-- <v-btn v-if="item.isOpen"
          rounded
          class="mr-2"
          color="error"
          @click="deleteStudyYear(item)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn> -->
      </template>

      <template v-slot:no-data>لا يوجد أي مادة محددة مسبقاً</template>
    </v-data-table>
    <AddStudyYear :show.sync="showAddNewyear" @yearAdded="refreshData()" />
  </div>
</template>

<script>
import { APIService } from "../../services/apiServices";
import AddStudyYear from "../studyYears/AddStudyYear.vue";
import debounce from "debounce";
const apiService = new APIService();
export default {
  name: "StudyYearIndex",
  components: { AddStudyYear },
  data: () => ({
    dialog: false,
    isClick: false,
    showAddNewyear: false,
    classOptions: [],
    classes: [],
    search: "",
    loadingClasses: false,
    headers: [
      {
        text: "الاسم",
        align: "start",
        value: "num",
      },
      { text: "الشعب في هذا العام الدرسي", value: "classesName" },

      { text: "العمليات", value: "action", sortable: false },
    ],
    allStudyYears: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      classes: [],
    },
    defaultItem: {
      name: "",
      classes: [],
    },
  }),
  created() {
    this.getStudyYears();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "مادة جديدة" : "تعديل";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    search(value) {
      if (!value) {
        return;
      }

      debounce(this.makeSearch, 200)(value, this);
    },
  },

  methods: {
    getStudyYears() {
      this.isGettingData = true;
      const that = this;
      apiService
        .getStudyYears()
        .then((response) => {
          that.isGettingData = false;
          this.allStudyYears = response.studyYears;
        })
        .catch(() => {
          that.isGettingData = false;
        });
    },
    editStudyYear(item) {
      this.editedIndex = this.allStudyYears.indexOf(item);
      //this.editedItem. = Object.assign({}, item);
      this.editedItem.id = item.id;
      this.editedItem.num = item.num;
      this.classes = item.classes;
      this.classOptions = item.classes;
      this.dialog = true;
    },

    deleteStudyYear(item) {
      var yearObject = {
        Id: item.id,
      };
      if (confirm("هل تريد بالتأكيد حذف هذا العام الدراسي")) {
        setTimeout(() => {
          apiService
            .deleteStudyYear(yearObject)
            .then(() => {
              this.refreshData();
            })
            .catch((error) => {
              this.error = "حدث خطأ ";
              alert(error);
              this.failed();
            });
        }, 3000);
      }
    },
    refreshData() {
      this.getStudyYears();
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        this.isClick = true;
        this.classes.map((c) => this.editedItem.classes.push(c.id));
        setTimeout(() => {
          apiService
            .updateStudyYear(this.editedItem)
            .then(() => {
              this.isClick = false;
              this.editedItem = Object.assign({}, this.defaultItem);
              this.close();
              this.refreshData();
            })
            .catch((error) => {
              this.error = "حدث خطأ ";
              alert(error);
              this.failed();
            });
        }, 3000);
        // } else {
        //   this.desserts.push(this.editedItem);
        // }
      }
    },
    makeSearch: async (value, self) => {
      // Handle empty value
      if (!value) {
        this.classOptions = [];
      }

      if (self.loadingClass) {
        return;
      }
      // console.log(self.classes);
      await apiService
        .searchClasses(value)
        .then((response) => {
          self.classOptions = response.classes;
        })
        .catch((error) => {
          self.error = "Unknown Error. Please check details and try again.";
          alert(error);
          self.failed();
        })
        .finally(() => (self.loadingLearner = false));
    },
  },
};
</script>
