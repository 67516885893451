<template>
  <v-dialog v-model="dialog" width="800px">
    <v-card>
      <v-card-title class="accent white--text darken-2"
        >فتح عام دراسي جديد</v-card-title
      >
      <v-container>
        <v-row justify="center">
          <div>
            <v-card flat width="800px" class="mx-auto">
              <v-card-text>
                <v-form ref="form" v-model="valid">
                  <v-row class="mb-2">
                    <v-col cols="12">
                      <v-text-field
                        prepend-icon="mdi-account-circle"
                        placeholder="الاسم"
                        v-model="year.num"
                        :rules="nameRules"
                        required
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mb-2">
                    <v-col>
                      <v-autocomplete
                        v-model="classes"
                        :items="classOptions"
                        :loading="loadingClasses"
                        :search-input.sync="search"
                        aria-required
                        chips
                        hide-details
                        hide-selected
                        cache-items
                        item-text="name"
                        item-value="id"
                        label="الشعب والصفوف"
                        prepend-icon="mdi-account-circle"
                        append-icon
                        placeholder="بحث عن صف حسب الاسم"
                        return-object
                        solo
                        multiple
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              البحث عن
                              <strong>الصفوف</strong>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                        <template
                          v-slot:selection="{ attr, on, item, selected }"
                        >
                          <v-chip
                            v-bind="attr"
                            :input-value="selected"
                            color="blue-grey"
                            class="white--text"
                            v-on="on"
                          >
                            <v-icon left>mdi-account-circle</v-icon>
                            <span v-text="item.name"></span>
                          </v-chip>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list-item-avatar
                            color="indigo"
                            class="headline font-weight-light white--text"
                          ></v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="item.name"
                            ></v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon>mdi-account-circle</v-icon>
                          </v-list-item-action>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </div>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer />

        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="addStudyYear"
          :loading="isClick"
          >حفظ</v-btn
        >
        <v-btn text @click="dialog = false">الغاء</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//new
import { APIService } from "../../services/apiServices";
import debounce from "debounce";
const apiService = new APIService();
export default {
  props: {
    show: Boolean
  },
  data: () => ({
    valid: true,
    addEnable: true,
    isClick: false,
    dialog: false,
    classOptions: [],
    classes: [],
    search: "",
    loadingClasses: false,
    year: {
      id: 0,
      num: "",
      classes: []
    },

    nameRules: [
      v => !!v || "يجب ادخال الاسم",
      v => (v && v.length <= 255) || "يجب أن يكون الاسم أقل من 255 حرف"
    ]
  }),
  methods: {
    makeSearch: async (value, self) => {
      // Handle empty value
      if (!value) {
        this.classOptions = [];
      }

      if (self.loadingClass) {
        return;
      }
      console.log(self.classes);
      await apiService
        .searchClasses(value)
        .then(response => {
          self.classOptions = response.classes;
        })
        .catch(error => {
          self.error = "Unknown Error. Please check details and try again.";
          alert(error);
          self.failed();
        })
        .finally(() => (self.loadingLearner = false));
    },

    addStudyYear() {
      if (this.$refs.form.validate()) {
        this.isClick = true;
        this.addEnable = true;
        this.classes.map(c => this.year.classes.push(c.id));

        setTimeout(() => {
          apiService
            .addStudyYear(this.year)
            .then(response => {
              if (response != "") {
                this.year = {
                  id: 0,
                  name: "",
                  classes: []
                };
                this.classes = [];
                this.dialog = false;
                this.$emit("yearAdded");
              } else {
                this.isClick = false;
              }
            })
            .catch(error => {
              this.error = "حدث خطأ ";
              alert(error);
              this.failed();
            })
            .finally(() => {
              this.isClick = false;
            });
        }, 3000);

        //this.dialog = false;
      }
    },
  

  },

  watch: {
    show(val) {
      this.dialog = val;
    },

    search(value) {
      if (!value) {
        return;
      }

      debounce(this.makeSearch, 200)(value, this);
    },

    dialog(val) {
      this.$emit("update:show", val);
      const that = this;
      setTimeout(() => {
        that.$refs.form.resetValidation();
      }, 300);
    }
  }
};
</script>
